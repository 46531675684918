// Get all table rows in the tbody
const tableRows = document.querySelectorAll('tbody tr');

// Loop through each table row
tableRows.forEach(row => {
  // Add a click event listener to each table row
  row.addEventListener('click', () => {
    // Get the link element within the row
    const link = row.querySelector('td a');
    
    // Get the URL from the link element
    const url = link.getAttribute('href');
    
    // Open the URL in a new tab or window
    window.open(url, '_blank');
  });
});
